import apiClient from '../libs/apiClient';
import {message} from 'antd';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const login = async credentials => {
  try {
    const {data: {access_token: token, redirect_url,isTwoFactorEnabled}} = await apiClient.post(
        'auth/login',
        credentials);
    if(isTwoFactorEnabled)  {
      return { redirect_url, isTwoFactorEnabled };
    }
    cookies.set('jwt', token,
        {path: '/', domain: `.${process.env.REACT_APP_DOMAIN}`});
    /* cookies.set('jwt', token,
        {path: '/', domain: `.${process.env.REACT_APP_DOMAIN_COOKIE}`}); */
    return { redirect_url, isTwoFactorEnabled };
  } catch 
    (error) {
      // /({response: {status}}) {
      //console.log(error); // Log full error for debugging
      const status = error.response?.status; // Safe access
    switch (status) {
      case 400:
        message.error(
            'The email or password you entered is incorrect, please try again.',
        );
        break;

      case 403:
        message.error(
            'Your account is temporarily blocked.',
        );
        break;

      default:
        message.error('Internal server error.');
    }
  }
};


const verifyTwoFactorAuth = async credentials => {
  try {
    const {data: {access_token: token, redirect_url,isTwoFactorEnabled}} = await apiClient.post(
        'auth/login/2fa/verify',
        credentials);
        //alert(isTwoFactorEnabled);alert(redirect_url);alert(token);
        /* cookies.set('jwt', token,
        {path: '/', domain: `.${process.env.REACT_APP_DOMAIN_COOKIE}`}); */
        cookies.set('jwt', token,
        {path: '/', domain: `.${process.env.REACT_APP_DOMAIN}`});
    return { redirect_url, isTwoFactorEnabled };
  } catch ({response: {status,data}}) {
    console.log(data?.message);
    message.error(data?.message || 'Internal server error.');
  }
};

const forgotPassword = async email => {
  try {
      await apiClient.post('auth/forgot-password', email);
  } catch (e) {
    message.error('Internal server error.');
  }
};

const resetPassword = async credentials => {
  try {

      const {status} = await apiClient.post('auth/reset-password', credentials);
      if (status === 201) {
          message.success(
              'Your password has been successfully reset',
          )
          return window.location.href = '/login'
      }
  } catch ({response: {status}}) {
    switch (status) {
      case 400:
        message.error(
            'Invalid token provided.',
        );
        break;

      case 422:
        message.error(
            'Your password must be at least 8 characters long, contain both uppercase and lowercase letters, a number, and a special character.',
        );
        break;

      default:
        message.error('Internal server error.');
    }
  }
};

export const Users = {
  login,
  forgotPassword,
  resetPassword,
  verifyTwoFactorAuth,
};
